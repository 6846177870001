
import { defineComponent } from 'vue'
import ServicesApiIntegrationsAlert from '@/components/pages/services/api/integrations/ServicesApiIntegrationsAlert.vue'
import ServicesApiIntegrationsEmpty from '@/components/pages/services/api/integrations/ServicesApiIntegrationsEmpty.vue'

export default defineComponent({
  components: {
    ServicesApiIntegrationsEmpty,
    ServicesApiIntegrationsAlert,
  },
})
